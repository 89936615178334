/* Home component styles */
.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #f0f4f8 0%, #d0e2f3 50%, #b0d0e8 100%);
  color: #333333;
  position: relative;
  transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(45deg, #f3f3f3, #e6e6e6, #f0f0f0);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.theme-toggle {
  background: none;
  border: none;
  color: #2c3e50;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.theme-toggle:hover {
  color: #3498db;
  transform: scale(1.1);
}

.intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
}

.profile-image {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center 10%;
  margin-bottom: 20px;
  border: 4px solid #3498db;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.profile-image:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(52, 152, 219, 0.3);
}

.name {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  transition: color 0.5s ease-in-out;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #34495e;
  transition: color 0.5s ease-in-out;
}

.bio {
  font-size: 1.1rem;
  color: #34495e;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  text-align: center;
  transition: color 0.5s ease-in-out;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  transition: color 0.5s ease-in-out;
}

/* Dark mode styles */
.dark-mode .home-container {
  background: rgb(26, 26, 26);
  color: #e0e0e0;
}

.dark-mode .background-animation {
  background: linear-gradient(45deg, #0a192f, #0c2447, #1a365d);
  background-size: 400% 400%;
}

.dark-mode .name,
.dark-mode .title,
.dark-mode .bio {
  color: #e0e0e0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.dark-mode .theme-toggle {
  color: #e0e0e0;
}

.dark-mode .theme-toggle:hover {
  color: #64b5f6;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .intro-section {
    padding: 2rem 1rem;
  }

  .name {
    font-size: 2rem;
  }

  .title {
    font-size: 1.2rem;
  }

  .bio {
    font-size: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .profile-image {
    width: 180px;
    height: 180px;
  }
}
