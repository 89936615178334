/* CV download section styles */
.cv-download-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.cv-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
}

.cv-button.download {
  background-color: #3498db;
  color: white;
}

.cv-button.view {
  background-color: #2ecc71;
  color: white;
}

.cv-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* Download button emoji styles */
.download-icon-wrapper {
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  position: relative;
}

.download-emoji {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  display: inline-block;
}

.cv-button.download:hover .download-emoji {
  animation: documentBounce 0.6s infinite;
}

@keyframes documentBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

/* View button eyes animation */
.animated-eyes {
  display: inline-block;
  transition: transform 0.3s ease;
  position: relative;
  top: -2px;
  margin-left: 4px;
}

.cv-button:hover .animated-eyes {
  animation: eyeMove 1s infinite;
  transform-origin: center;
}

@keyframes eyeMove {
  0% { transform: translateX(0); }
  25% { transform: translateX(2px); }
  50% { transform: translateX(-2px); }
  75% { transform: translateX(2px); }
  100% { transform: translateX(0); }
}

/* Button hover effects */
.cv-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.cv-button:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Dark mode styles */
.dark-mode .cv-button.download {
  background-color: #2980b9;
}

.dark-mode .cv-button.view {
  background-color: #27ae60;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .cv-download-section {
    flex-direction: column;
    gap: 0.75rem;
  }

  .cv-button {
    width: 100%;
    max-width: 250px;
  }
}
