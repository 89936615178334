.education-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: inherit;
  overflow-x: hidden;
  position: relative;
  transition: color 0.5s ease-in-out;
  padding: 4rem 2rem;
}

.section-title {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
}

.tab-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.tab-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid #3498db;
  color: #3498db;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 0.5rem;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 2px 5px rgba(52, 152, 219, 0.2);
}

.tab-button.active {
  background-color: #3498db;
  color: white;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

.tab-button:hover {
  background-color: rgba(52, 152, 219, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #3498db;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.timeline-item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #3498db;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #3498db;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #3498db;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #3498db;
  border-width: 10px 10px 10px 0;
  border-color: transparent #3498db transparent transparent;
}

.right::after {
  left: -16px;
}

.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-content h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.timeline-content .date {
  font-style: italic;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
}

.timeline-content .degree,
.timeline-content .platform {
  font-weight: bold;
  color: #3498db;
  margin-bottom: 0.5rem;
}

.timeline-content p {
  color: #34495e;
  line-height: 1.6;
}

.btn-certificate {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 2px 5px rgba(52, 152, 219, 0.2);
}

.btn-certificate:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
}

.certificate-preview {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: white;
  border: 2px solid #3498db;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
}

.certificate-preview h4 {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.certificate-preview p {
  color: #34495e;
  margin-bottom: 0.5rem;
}

.certificate-preview ul {
  padding-left: 1.5rem;
}

.certificate-preview li {
  color: #34495e;
  margin-bottom: 0.3rem;
}

/* Dark mode styles */
.dark-mode .section-title {
  background: linear-gradient(135deg, #64b5f6, #81c784);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dark-mode .tab-button {
  border-color: #64b5f6;
  color: #64b5f6;
  box-shadow: 0 2px 5px rgba(100, 181, 246, 0.2);
}

.dark-mode .tab-button.active {
  background-color: #64b5f6;
  color: #1a1a1a;
  box-shadow: 0 4px 8px rgba(100, 181, 246, 0.3);
}

.dark-mode .tab-button:hover {
  background-color: rgba(100, 181, 246, 0.2);
  box-shadow: 0 4px 10px rgba(100, 181, 246, 0.5);
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.dark-mode .timeline::after {
  background-color: #64b5f6;
}

.dark-mode .timeline-item::after {
  background-color: #1a1a1a;
  border-color: #64b5f6;
}

.dark-mode .left::before {
  border-color: transparent transparent transparent #64b5f6;
}

.dark-mode .right::before {
  border-color: transparent #64b5f6 transparent transparent;
}

.dark-mode .timeline-content {
  background-color: #2c3e50;
  color: #ecf0f1;
}

.dark-mode .timeline-content h3 {
  color: #ecf0f1;
}

.dark-mode .timeline-content .date {
  color: #bdc3c7;
}

.dark-mode .timeline-content .degree,
.dark-mode .timeline-content .platform {
  color: #64b5f6;
}

.dark-mode .timeline-content p {
  color: #ecf0f1;
}

.dark-mode .btn-certificate {
  background-color: #64b5f6;
  color: #1a1a1a;
  box-shadow: 0 2px 5px rgba(100, 181, 246, 0.2);
}

.dark-mode .btn-certificate:hover {
  background-color: #4a90e2;
  box-shadow: 0 4px 10px rgba(100, 181, 246, 0.5);
}

.dark-mode .certificate-preview {
  background-color: #2c3e50;
  border-color: #64b5f6;
}

.dark-mode .certificate-preview h4,
.dark-mode .certificate-preview p,
.dark-mode .certificate-preview li {
  color: #ecf0f1;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .timeline::after {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item::before {
    left: 60px;
    border: medium solid #3498db;
    border-width: 10px 10px 10px 0;
    border-color: transparent #3498db transparent transparent;
  }

  .left::after, .right::after {
    left: 15px;
  }

  .right {
    left: 0%;
  }
  
  .certificate-preview {
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    max-width: calc(100% - 2rem);
  }
}

@media screen and (max-width: 480px) {
  .section-title {
    font-size: 2rem;
    min-height: 7rem;
    line-height: 1.2;
  }

  .timeline-content h3 {
    font-size: 1.2rem;
  }

  .timeline-content p {
    font-size: 0.9rem;
  }

  .btn-certificate {
    font-size: 0.75rem; /* Reduced from 0.9rem */
    padding: 0.5rem 1rem; /* Reduced padding */
    letter-spacing: 0.5px; /* Reduced letter spacing */
  }

  .tab-button {
    font-size: 0.75rem; /* Reduced from 0.9rem */
    padding: 0.5rem 1rem; /* Reduced padding */
    letter-spacing: 0.5px; /* Reduced letter spacing */
    margin: 0 0.25rem; /* Reduced margin between buttons */
  }
}
