.portfolio-section {
  padding: 82px 2rem 2rem 2rem;
  min-height: 100vh;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #f0f4f8 0%, #d0e2f3 50%, #b0d0e8 100%);
  color: #333;
  margin-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.portfolio-section.dark-mode {
  background: #1a1a1a;
  color: #ffffff;
}

.portfolio-content {
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.portfolio-container {
  display: grid;
  gap: 2rem;
}

.portfolio-section-item {
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
}

.dark-mode .portfolio-section-item {
  background: #1E2124;
}

.portfolio-section-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(46, 139, 87, 0.1);
  margin-bottom: 1rem;
}

.dark-mode .section-header {
  border-bottom-color: rgba(152, 223, 175, 0.1);
}

.portfolio-section-item:not(.expanded) .section-header {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.section-header h3 {
  margin: 0;
  color: #2E8B57;
  font-size: 1.5rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.dark-mode .section-header h3 {
  color: #98DFAF;
}

.section-header:hover h3 {
  color: #3AA66D;
}

.dark-mode .section-header:hover h3 {
  color: #2EE59D;
}

/* CSS Metrics styles */
.css-metrics-title {
  text-decoration: none;
  color: inherit;
  display: block;
}

.metrics-link {
  color: #2E8B57;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
  font-weight: 500;
}

.dark-mode .metrics-link {
  color: #2EE59D;
}

.metrics-link:hover {
  color: #3AA66D;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.metric-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark-mode .metric-item {
  background: #1E2124;
}

.metric-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(46, 139, 87, 0.2);
}

.dark-mode .metric-item:hover {
  box-shadow: 0 8px 15px rgba(152, 223, 175, 0.2);
}

.metric-item h4 {
  color: #2E8B57;
  font-size: 1.1rem;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.dark-mode .metric-item h4 {
  color: #98DFAF;
}

.metric-value {
  color: #2E8B57;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
}

.dark-mode .metric-value {
  color: #98DFAF;
}

/* Architecture grid styles */
.architecture-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  width: 100%;
}

.architecture-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.architecture-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(46, 139, 87, 0.1);
  width: 100%;
}

.dark-mode .architecture-card {
  background: #1E2124;
  border-color: rgba(152, 223, 175, 0.1);
}

.architecture-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(46, 139, 87, 0.15);
  border-color: rgba(46, 139, 87, 0.3);
}

.dark-mode .architecture-card:hover {
  box-shadow: 0 6px 12px rgba(152, 223, 175, 0.15);
  border-color: rgba(152, 223, 175, 0.3);
}

.architecture-card h3 {
  color: #2E8B57;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.dark-mode .architecture-card h3 {
  color: #98DFAF;
}

/* Code button styles */
.code-toggle-btn {
  background: rgba(46, 139, 87, 0.1);
  color: #2E8B57;
  border: 1px solid rgba(46, 139, 87, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: auto;
  align-self: flex-start;
}

.dark-mode .code-toggle-btn {
  background: rgba(152, 223, 175, 0.1);
  color: #98DFAF;
  border-color: rgba(152, 223, 175, 0.3);
}

.code-toggle-btn:hover {
  background: rgba(46, 139, 87, 0.2);
  border-color: rgba(46, 139, 87, 0.5);
}

.dark-mode .code-toggle-btn:hover {
  background: rgba(152, 223, 175, 0.2);
  border-color: rgba(152, 223, 175, 0.5);
}

/* Code section styles */
.code-section {
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(46, 139, 87, 0.2);
  background: #f8f8f8;
  width: 100%;
}

.dark-mode .code-section {
  border-color: rgba(152, 223, 175, 0.2);
  background: #1a1a1a;
}

.code-block {
  margin: 0 !important;
  border-radius: 8px !important;
  font-size: 0.9rem !important;
  padding: 1rem !important;
  width: 100% !important;
  box-sizing: border-box !important;
  font-family: 'Fira Code', monospace !important;
}

.dark-mode .code-block {
  background: #1a1a1a !important;
}

/* Tech Stack styles */
.tech-stack-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.tech-stack-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid rgba(46, 139, 87, 0.1);
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 200px;
}

.dark-mode .tech-stack-item {
  background-color: #1E2124;
  border-color: rgba(152, 223, 175, 0.1);
}

.tech-stack-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.tech-stack-icon {
  font-size: 3.5rem;
  margin-bottom: 15px;
  color: #2E8B57 !important;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.dark-mode .tech-stack-icon {
  color: #98DFAF !important;
}

.tech-stack-item:hover .tech-stack-icon {
  opacity: 0;
}

.tech-stack-name {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 10px;
  color: #2c3e50;
  font-weight: 600;
  transition: opacity 0.3s ease;
}

.dark-mode .tech-stack-name {
  color: #ffffff;
}

.tech-stack-item:hover .tech-stack-name {
  opacity: 0;
}

.tech-stack-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(46, 139, 87, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 15px;
  padding: 15px;
}

.dark-mode .tech-stack-overlay {
  background-color: rgba(152, 223, 175, 0.95);
}

.tech-stack-item:hover .tech-stack-overlay {
  opacity: 1;
}

.tech-stack-overlay p {
  color: #ffffff;
  font-size: 0.95rem;
  text-align: center;
  line-height: 1.4;
  margin: 0;
  max-width: 100%;
}

.dark-mode .tech-stack-overlay p {
  color: #1a1a1a;
}

.expand-toggle {
  background: none;
  border: 2px solid #2E8B57;
  color: #2E8B57;
  font-size: 1.5rem;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  padding: 0;
  line-height: 1;
}

.dark-mode .expand-toggle {
  color: #98DFAF;
  border-color: #98DFAF;
}

.expand-toggle:hover {
  background: rgba(46, 139, 87, 0.1);
  transform: scale(1.1);
}

.dark-mode .expand-toggle:hover {
  background: rgba(152, 223, 175, 0.1);
}

.portfolio-section-item p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  opacity: 0.9;
}

@media (max-width: 1024px) {
  .tech-stack-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .metrics-grid {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .metric-item {
    padding: 1.5rem;
  }

  .metric-value {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .portfolio-section {
    padding: 62px 1rem 1rem 1rem;
  }

  .portfolio-title {
    font-size: 2rem;
  }

  .portfolio-section-item {
    padding: 1.5rem;
  }

  .section-header h3 {
    font-size: 1.3rem;
  }

  .expand-toggle {
    width: 25px;
    height: 25px;
    font-size: 1.2rem;
  }

  .tech-stack-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .tech-stack-icon {
    font-size: 3rem;
  }

  .tech-stack-name {
    font-size: 1rem;
  }

  .tech-stack-overlay p {
    font-size: 0.9rem;
  }

  .code-toggle-btn {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  .metrics-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1rem;
  }

  .metric-item {
    padding: 1.5rem;
  }

  .metric-value {
    font-size: 3rem;
  }
}

@media (max-width: 480px) {
  .tech-stack-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .tech-stack-icon {
    font-size: 2.5rem;
  }

  .metrics-grid {
    padding: 0.5rem;
  }

  .metric-item {
    padding: 1rem;
  }

  .metric-value {
    font-size: 2.5rem;
  }
}
