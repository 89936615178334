/* About Section Styles */
.about-container {
  width: 100%;
  min-height: auto;
  margin: 0 auto;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f0f4f8 0%, #d0e2f3 50%, #b0d0e8 100%);
  background-size: 100% 100%;
  background-attachment: fixed;
  color: #333333;
}

.about-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #333333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dark mode styles */
.dark-mode.about-container {
  background: rgb(26, 26, 26);
  background-size: 100% 100%;
  background-attachment: fixed;
  color: rgb(255, 255, 255);
}

.dark-mode .about-title {
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.1);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .about-container {
    padding: 6rem 1rem 2rem 1rem; /* Increased top padding to account for header */
  }

  .about-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    padding-top: 1rem; /* Additional padding for better spacing */
  }
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}
