/* Projects component styles */
.projects-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.work-experience-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  box-sizing: border-box;
}

.personal-projects-section {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
}

.section-title {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  animation: fadeInText 1s ease-in-out;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Timeline styles */
.timeline {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background: linear-gradient(180deg, #3498db, #2ecc71);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  animation: grow 2s ease-out forwards, pulse 3s infinite;
  transform-origin: top;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 3px rgba(52, 152, 219, 0.3), 0 0 6px rgba(46, 204, 113, 0.2);
  }
  50% {
    box-shadow: 0 0 6px rgba(52, 152, 219, 0.4), 0 0 9px rgba(46, 204, 113, 0.3);
  }
  100% {
    box-shadow: 0 0 3px rgba(52, 152, 219, 0.3), 0 0 6px rgba(46, 204, 113, 0.2);
  }
}

.timeline-item {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  z-index: 2;
  opacity: 0;
  transform: translateX(-50px);
  animation: fadeInSlide 0.5s ease-out forwards;
  animation-delay: calc(var(--item-index) * 0.2s);
}

.timeline-item:hover {
  transform: scale(1.03) translateY(-10px);
}

@keyframes fadeInSlide {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.timeline-item::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  border: 4px solid #3498db;
  top: 12px;
  border-radius: 50%;
  z-index: 3;
  transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::before,
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 2;
  border: medium solid #3498db;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.left::before {
  right: 30px;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #3498db;
}

.right::before {
  left: 30px;
  border-width: 10px 10px 10px 0;
  border-color: transparent #3498db transparent transparent;
}

.left::after {
  right: -19px;
}

.right::after {
  left: -19px;
}

.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease-in-out;
  text-align: center;
  z-index: 2;
}

.timeline-content:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.timeline-item:hover::before {
  opacity: 0;
  transform: scale(0);
}

.company-logo {
  width: 120px;
  height: 120px;
  margin: 0 auto 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.timeline-content:hover .company-logo {
  transform: rotate(10deg);
}

.company-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.company-logo a:hover img {
  transform: scale(1.1);
}

.timeline-content h3 {
  color: #2c3e50;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  transition: color 0.5s ease-in-out;
}

.timeline-content h3 a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.timeline-content h3 a:hover {
  color: #3498db;
  text-decoration: none;
}

.timeline-content p {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.6;
  transition: color 0.5s ease-in-out;
}

/* Projects grid styles */
.projects-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 1200px;
}

.project-card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
}

.project-card:hover {
  transform: scale(1.05) translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.project-icon {
  font-size: 2.5rem;
  color: #3498db;
  margin-bottom: 1.5rem;
  transition: color 0.5s ease-in-out, transform 0.3s ease;
}

.project-card:hover .project-icon {
  animation: bounce 0.5s ease infinite alternate;
}

@keyframes bounce {
  from { transform: translateY(0); }
  to { transform: translateY(-10px); }
}

.project-card h3 {
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  transition: color 0.5s ease-in-out;
}

.project-card h3 a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.project-card h3 a:hover {
  color: #3498db;
}

.project-card h4 {
  color: #3498db;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  transition: color 0.5s ease-in-out;
}

.project-card p {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  transition: color 0.5s ease-in-out;
}

.visit-site-btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.visit-site-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* martinsil.es specific styles */
.martinsiles-card {
  background-color: rgba(52, 152, 219, 0.1);
  border: 2px solid #3498db;
  width: 95%;
  margin: 0 auto;
}

.martinsiles-card:hover {
  background-color: rgba(52, 152, 219, 0.2);
}

.martinsiles-card .project-icon {
  color: #3498db;
}

.martinsiles-card h3 {
  color: #2c3e50;
}

.martinsiles-card h4 {
  color: #3498db;
}

/* Dark mode styles */
.dark-mode .section-title {
  color: #f0f0f0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.dark-mode .timeline::after {
  background-color: #4a90e2;
}

.dark-mode .timeline-item::after {
  background-color: rgb(26, 26, 26);
  border-color: #4a90e2;
}

.dark-mode .left::before {
  border-color: transparent transparent transparent #4a90e2;
}

.dark-mode .right::before {
  border-color: transparent #4a90e2 transparent transparent;
}

.dark-mode .timeline-content {
  background-color: #333333;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
}

.dark-mode .timeline-content:hover {
  background-color: #404040;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);
}

.dark-mode .timeline-content h3,
.dark-mode .timeline-content p {
  color: #e0e0e0;
}

.dark-mode .timeline-content h3 a {
  color: #e0e0e0;
}

.dark-mode .timeline-content h3 a:hover {
  color: #4a90e2;
}

.dark-mode .project-card {
  background-color: #333333;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .project-card:hover {
  background-color: #404040;
}

.dark-mode .project-icon {
  color: #4a90e2;
}

.dark-mode .project-card h3,
.dark-mode .project-card p {
  color: #e0e0e0;
}

.dark-mode .project-card h4 {
  color: #4a90e2;
}

.dark-mode .project-card h3 a:hover {
  color: #4a90e2;
}

.dark-mode .visit-site-btn {
  background-color: #4a90e2;
  color: #f0f0f0;
}

.dark-mode .visit-site-btn:hover {
  background-color: #3a7bc8;
}

.dark-mode .martinsiles-card {
  background-color: rgba(74, 144, 226, 0.1);
  border: 2px solid #4a90e2;
}

.dark-mode .martinsiles-card:hover {
  background-color: rgba(74, 144, 226, 0.2);
}

.dark-mode .martinsiles-card .project-icon,
.dark-mode .martinsiles-card h4 {
  color: #4a90e2;
}

.dark-mode .martinsiles-card h3 {
  color: #e0e0e0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .timeline::after {
    left: 31px;
  }
  
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  
  .timeline-item::before {
    left: 60px;
    border: medium solid #3498db;
    border-width: 10px 10px 10px 0;
    border-color: transparent #3498db transparent transparent;
  }

  .left::after, .right::after {
    left: 12px;
  }

  .right {
    left: 0%;
  }

  .martinsiles-card {
    width: 80%;
  }

  /* Dark mode mobile fixes */
  .dark-mode .timeline-item::before {
    border-color: transparent #4a90e2 transparent transparent;
  }
}

@media screen and (max-width: 480px) {
  .company-logo {
    width: 100px;
    height: 100px;
  }

  .project-icon {
    font-size: 2rem;
  }

  .project-card h3 {
    font-size: 1.5rem;
  }

  .project-card h4 {
    font-size: 1.1rem;
  }

  .project-card p {
    font-size: 0.9rem;
  }

  .visit-site-btn {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  .martinsiles-card {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem;
  }
}
