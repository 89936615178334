.testimonials {
  padding: 4rem 2rem;
  color: var(--text-color);
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  position: relative;
  z-index: 1;
}

.testimonial-card {
  background-color: var(--card-bg-color);
  border: var(--card-border);
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  box-shadow: var(--card-shadow);
  text-align: center;
  position: relative;
  z-index: 2;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, box-shadow;
  transition-property: transform, box-shadow;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.testimonial-author {
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.testimonial-author a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.testimonial-author a:hover {
  color: #2980b9;
}

.testimonial-position {
  font-style: italic;
  color: var(--secondary-text-color);
}

/* Hover effects */
.testimonial-card:hover {
  transform: scale(1.02);
  box-shadow: var(--card-shadow-hover);
  transition-duration: 0.3s, 0.3s;
}

.testimonial-card:not(:hover) {
  transition-duration: 0.1s, 0.1s;
}

/* Light mode styles */
body:not(.dark-mode) .testimonials {
  --bg-color: #f0f0f0;
  --text-color: #000000;
  --card-bg-color: #f0f0f0;
  --card-border: none;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --card-shadow-hover: 0 10px 20px rgba(0, 0, 0, 0.2);
  --secondary-text-color: #555555;
}

/* Dark mode styles */
body.dark-mode .testimonials {
  --bg-color: #1a1a1a;
  --text-color: #ffffff;
  --card-bg-color: rgba(255, 255, 255, 0.05);
  --card-border: 1px solid rgba(255, 255, 255, 0.1);
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  --card-shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.3);
  --secondary-text-color: #bdc3c7;
}

/* Responsive styles */
@media (max-width: 768px) {
  .testimonials {
    padding: 3rem 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .testimonial-card {
    padding: 1.5rem;
  }
}
