.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.loading-text {
  color: var(--text-color);
  font-size: 1.2rem;
  margin-top: 1rem;
}

/* Nuevo estilo para la carga de secciones */
.section-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
  background-color: var(--background-color);
  margin: 1rem 0;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--text-color);
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Estilos para modo oscuro */
.dark-mode .loading-spinner {
  border-color: var(--text-color-dark);
  border-top-color: transparent;
}

/* Optimizaciones de rendimiento */
.loading-container,
.section-loading {
  will-change: opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.loading-spinner {
  will-change: transform;
  transform: translateZ(0);
}
