.swal2-popup {
  border-radius: 15px !important;
  padding: 2em !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) !important;
}

.swal2-title {
  font-size: 1.5em !important;
  margin-bottom: 0.5em !important;
}

.swal2-timer-progress-bar {
  background: linear-gradient(to right, #3498db, #2ecc71) !important;
  height: 0.25rem !important;
}

.dark-mode .swal2-popup {
  background: #2d2d2d !important;
  color: #ffffff !important;
}

.dark-mode .swal2-title {
  color: #ffffff !important;
}

.dark-mode .swal2-timer-progress-bar {
  background: linear-gradient(to right, #3498db, #2ecc71) !important;
}

.swal2-close {
  font-size: 2em !important;
  color: #95a5a6 !important;
  transition: color 0.3s ease !important;
}

.swal2-close:hover {
  color: #3498db !important;
}

.dark-mode .swal2-close {
  color: #bdc3c7 !important;
}

.dark-mode .swal2-close:hover {
  color: #3498db !important;
}

/* Arreglar el fondo y el padding */
.swal2-container {
  padding: 0 !important;
  backdrop-filter: blur(5px) !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

/* Prevenir el scroll del body */
.swal2-shown {
  padding-right: 0 !important;
  overflow-y: auto !important;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: auto !important;
  padding-right: 0 !important;
}

/* Animación suave */
.swal2-show {
  animation: swal2-show 0.2s ease-out !important;
}

.swal2-hide {
  animation: swal2-hide 0.2s ease-out !important;
}

@keyframes swal2-show {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}
