.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 70px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.header.hidden {
  transform: translateY(-100%);
}

.header.light {
  background-color: #ffffff;
  color: var(--text-color-dark);
}

.header.dark {
  background-color: #1a1a1a;
  color: var(--text-color-light);
}

.header-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 50px;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 4rem;
  align-items: center;
}

.nav-menu ul li {
  display: flex;
  align-items: center;
}

.nav-menu ul li a {
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  white-space: nowrap;
  color: inherit;
  cursor: pointer;
  transition: color 0.2s ease;
}

.light .nav-menu ul li a {
  color: var(--text-color-dark);
}

.dark .nav-menu ul li a {
  color: var(--text-color-light);
}

.nav-menu ul li a:hover {
  color: var(--secondary-color);
}

.controls-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding-right: 4rem;
  background-color: inherit;
}

.controls-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.theme-toggle, .language-toggle {
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: transform 0.2s ease, color 0.2s ease;
  background: transparent;
}

.light .theme-toggle, .light .language-toggle {
  color: var(--text-color-dark);
}

.dark .theme-toggle, .dark .language-toggle {
  color: var(--text-color-light);
}

.theme-toggle:hover, .language-toggle:hover {
  transform: scale(1.1);
  color: var(--secondary-color);
}

.language-toggle {
  font-size: 1rem;
  font-weight: bold;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    height: 60px;
  }

  .header-content {
    margin-left: 0;
  }

  .menu-icon {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 1rem;
    z-index: 100;
  }

  .nav-menu {
    position: fixed;
    top: 60px;
    right: -100%;
    width: 60%;
    height: calc(100vh - 60px);
    transition: right var(--transition-speed) ease-in-out;
  }

  .light .nav-menu {
    background-color: #ffffff;
  }

  .dark .nav-menu {
    background-color: #1a1a1a;
  }

  .nav-menu.show {
    right: 0;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    gap: 2rem;
  }

  .nav-menu ul li a {
    font-size: 1.2rem;
  }

  .controls-wrapper {
    height: 60px;
    padding-right: 1rem;
  }

  .controls-container {
    gap: 0.5rem;
  }
}

@media screen and (min-width: 769px) {
  .menu-icon {
    display: none;
  }

  .nav-menu {
    display: flex !important;
  }
}
