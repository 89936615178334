footer {
  background: linear-gradient(to bottom, rgba(44, 62, 80, 0.8), rgba(44, 62, 80, 1));
  color: var(--text-color-light);
  padding: 1rem 0;
  position: relative;
  text-align: center;
}

footer::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
}

.social-icon {
  font-size: 1.75rem;
  color: var(--text-color-light);
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: var(--secondary-color);
  transform: scale(1.1);
}

.copyright {
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.dark-mode footer {
  background: linear-gradient(to bottom, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 1));
}

.dark-mode footer::before {
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.05));
}

.dark-mode .social-icon:hover {
  color: #3498db;
}

@media screen and (max-width: 768px) {
  .footer-links {
    gap: 1.25rem;
  }

  .social-icon {
    font-size: 1.5rem;
  }

  .copyright {
    font-size: 0.75rem;
  }
}