/* Contact component styles */
.contact-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}

.contact-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.contact-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  display: inline-block;
}

.gradient-text {
  background: linear-gradient(45deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradientFlow 5s ease infinite;
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 3px;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  transition: width 0.5s ease;
  animation: underlineGrow 1s forwards 0.5s;
}

@keyframes underlineGrow {
  to {
    width: 100%;
  }
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  position: relative;
}

.input-container {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #3498db;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

input,
textarea {
  width: 100%;
  padding: 0.8rem 0.8rem 0.8rem 2.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

textarea {
  min-height: 150px;
  resize: vertical;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

label {
  position: absolute;
  left: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #7f8c8d;
  pointer-events: none;
  transition: 0.3s ease all;
  padding: 0 0.3rem;
}

textarea + label {
  top: 1rem;
  transform: none;
}

input:focus + label,
textarea:focus + label,
input:not(:placeholder-shown) + label,
textarea:not(:placeholder-shown) + label,
label.filled {
  top: -0.5rem;
  left: 0.5rem;
  font-size: 0.8rem;
  color: #3498db;
  background-color: white;
}

input::placeholder,
textarea::placeholder {
  color: transparent;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.submit-button {
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

/* Estilos para reCAPTCHA */
.recaptcha-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.recaptcha-container > div {
  transform-origin: left top;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 400px) {
  .recaptcha-container > div {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 360px) {
  .recaptcha-container > div {
    transform: scale(0.8);
  }
}

/* Dark mode styles */
.dark-mode.contact-container {
  background: #1a1a1a;
  color: #e0e0e0;
}

.dark-mode label {
  color: #bdc3c7;
  background-color: #2c3e50;
}

.dark-mode input,
.dark-mode textarea {
  background-color: #2c3e50;
  border-color: #34495e;
  color: #e0e0e0;
}

.dark-mode input:focus,
.dark-mode textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.4);
}

.dark-mode input:focus + label,
.dark-mode textarea:focus + label,
.dark-mode input:not(:placeholder-shown) + label,
.dark-mode textarea:not(:placeholder-shown) + label,
.dark-mode label.filled {
  color: #3498db;
  background-color: #2c3e50;
}

.dark-mode .input-icon {
  color: #3498db;
}

.dark-mode .submit-button {
  background-color: #3498db;
}

.dark-mode .submit-button:hover {
  background-color: #2980b9;
}

.dark-mode input::placeholder,
.dark-mode textarea::placeholder {
  color: transparent;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .contact-content {
    padding: 1rem;
  }

  .contact-title {
    font-size: 2rem;
  }

  input,
  textarea {
    font-size: 0.9rem;
  }

  label {
    font-size: 0.9rem;
  }

  input:focus + label,
  textarea:focus + label,
  input:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label,
  label.filled {
    font-size: 0.7rem;
  }
}
